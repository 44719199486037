.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin:2% auto;
    flex-wrap: wrap;

}
.title{
    font-family: "Heebo";
    font-size: 3.5rem;
    text-align: center;
    margin:2% auto;
}

@media only screen and (max-width: 350px) {

    .title{
        font-family: "Heebo";
        font-size: 2.2rem;
        text-align: center;
        margin:4% auto;
    }

}


@media only screen and (min-width: 350px)  and (max-width: 450px) {

    .title{
        font-family: "Heebo";
        font-size:2.4rem;
        text-align: center;
        margin:4% auto;
    }

}
@media only screen and (min-width: 450px)  and (max-width: 550px) {

    .title{
        font-family: "Heebo";
        font-size:2.5rem;
        text-align: center;
        margin:4% auto;
    }

}
@media only screen and (min-width: 550px)  and (max-width: 750px) {

    .title{
        font-family: "Heebo";
        font-size:2.7rem;
        text-align: center;
        margin:4% auto;
    }

}
@media only screen and (min-width: 750px)  and (max-width: 950px) {

    .title{
        font-family: "Heebo";
        font-size:3rem;
        text-align: center;
        margin:4% auto;
    }

}
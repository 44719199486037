.uiverse {
    --duration: 7s;
    --easing: linear;
    --c-color-1: #8a2be2; /* Dark purple */
    --c-color-2: #00008b; /* Dark blue */
    --c-color-3: #ffffff; /* White */
    --c-color-4: #8b45137f; /* Brown */
    --c-shadow: rgba(0, 0, 0, 0.5);
    --c-shadow-inset-top: none;
    --c-shadow-inset-bottom: none;
    --c-radial-inner: #ffffff;
    --c-radial-outer: none;

    --c-color: black;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    outline: none;
  
    position: relative;
    cursor: pointer;
    border: none;
    display: table;
    border-radius: 24px;
    padding: 0;
    margin: 0 auto;
    /* Adjust the width */
    width: 200px; /* Adjust as needed */
 
    font-family: "Assistant";
    text-align: center;
 
    font-size: 18px;
    color: var(--c-color);
    background: radial-gradient(circle, var(--c-radial-inner), var(--c-radial-outer) 80%);
    box-shadow: 0 0 14px var(--c-shadow);
  }
  
  .uiverse:before {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    box-shadow: inset 0 3px 12px var(--c-shadow-inset-top), inset 0 -3px 4px var(--c-shadow-inset-bottom);
  }
  
  .wrapper {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
    border-radius: 24px;
    min-width: 132px;
    padding: 12px 0;
  }
  
  .wrapper span {
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  
  .circle {
    position: absolute;
    left: 0;
    top: 0;
    width:70px;
    height: 70px;
    border-radius: 50%;
    filter: blur(var(--blur, 8px));
    background: var(--background, transparent);
    transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
    animation: var(--animation, none) var(--duration) var(--easing) infinite;
  }
  
  .circle.circle1, .circle.circle9, .circle.circle10 {
    --background: var(--c-color-4);
  }
  
  .circle.circle3, .circle.circle4 {
    --background: var(--c-color-2);
    --blur: 18px;
  }
  
  .circle.circle5, .circle.circle6 {
    --background: var(--c-color-3);
    --blur: 18px;
  }
  
  .circle.circle2, .circle.circle7, .circle.circle8, .circle.circle11, .circle.circle12 {
    --background: var(--c-color-1);
    --blur: 18px;
  }
  
  .circle.circle1 {
    --x: 0;
    --y: -40px;
    --animation: circle-1;
  }
  
  .circle.circle2 {
    --x: 92px;
    --y: 8px;
    --animation: circle-2;
  }
  
  .circle.circle3 {
    --x: -12px;
    --y: -12px;
    --animation: circle-3;
  }
  
  .circle.circle4 {
    --x: 80px;
    --y: -12px;
    --animation: circle-4;
  }
  
  .circle.circle5 {
    --x: 12px;
    --y: -4px;
    --animation: circle-5;
  }
  
  .circle.circle6 {
    --x: 56px;
    --y: 16px;
    --animation: circle-6;
  }
  
  .circle.circle7 {
    --x: 8px;
    --y: 28px;
    --animation: circle-7;
  }
  
  .circle.circle8 {
    --x: 28px;
    --y: -4px;
    --animation: circle-8;
  }
  
  .circle.circle9 {
    --x: 20px;
    --y: -12px;
    --animation: circle-9;
  }
  
  .circle.circle10 {
    --x: 64px;
    --y: 16px;
    --animation: circle-10;
  }
  
  .circle.circle11 {
    --x: 4px;
    --y: 4px;
    --animation: circle-11;
  }
  
  .circle.circle12 {
    --blur: 18px;
    --x: 52px;
    --y: 4px;
    --animation: circle-12;
  }
  
  @keyframes circle-1 {
    33% {
      transform: translate(0px, 16px) translateZ(0);
    }
  
    66% {
      transform: translate(12px, 64px) translateZ(0);
    }
  }
  
  @keyframes circle-2 {
     33% {
      transform: translate(80px, -10px) translateZ(0);
    }
  
    66% {
      transform: translate(72px, -48px) translateZ(0);
    }
  }
  
  @keyframes circle-3 {
    33% {
      transform: translate(20px, 12px) translateZ(0);
    }
  
    66% {
      transform: translate(12px, 4px) translateZ(0);
    }
  }
  
  @keyframes circle-4 {
    33% {
      transform: translate(76px, -12px) translateZ(0);
    }
  
    66% {
      transform: translate(112px, -8px) translateZ(0);
    }
  }
  
  @keyframes circle-5 {
    33% {
      transform: translate(84px, 28px) translateZ(0);
    }
  
    66% {
      transform: translate(40px, -32px) translateZ(0);
    }
  }
  
  @keyframes circle-6 {
    33% {
      transform: translate(28px, -16px) translateZ(0);
    }
  
    66% {
      transform: translate(76px, -56px) translateZ(0);
    }
  }
  
  @keyframes circle-7 {
    33% {
      transform: translate(8px, 28px) translateZ(0);
    }
  
    66% {
      transform: translate(20px, -60px) translateZ(0);
    }
  }
  
  @keyframes circle-8 {
    33% {
      transform: translate(32px, -4px) translateZ(0);
    }
  
    66% {
      transform: translate(56px, -20px) translateZ(0);
    }
  }
  
  @keyframes circle-9 {
    33% {
      transform: translate(20px, -12px) translateZ(0);
    }
  
    66% {
      transform: translate(80px, -8px) translateZ(0);
    }
  }
  
  @keyframes circle-10 {
    33% {
      transform: translate(68px, 20px) translateZ(0);
    }
  
    66% {
      transform: translate(100px, 28px) translateZ(0);
    }
  }
  
  @keyframes circle-11 {
    33% {
      transform: translate(4px, 4px) translateZ(0);
    }
  
    66% {
      transform: translate(68px, 20px) translateZ(0);
    }
  }
  
  @keyframes circle-12 {
    33% {
      transform: translate(56px, 0px) translateZ(0);
    }
  
    66% {
      transform: translate(60px, -32px) translateZ(0);
    }
  }
  
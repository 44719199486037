.what {
    display: flex;
    flex-direction: row;
    width: 750px;
          height:auto;
    border-radius: 100px;
    margin:3% auto;
}
.left{
display: flex;
flex-direction: column;
justify-content:center;
margin:auto 0;
padding:0 20px;
flex:4;
}
.buttonWrapper{

display: flex;
justify-content: center;
margin:auto 0;

}
.button{
border-radius:20px;
width:150px;
height:60px;
color: rgb(0, 0, 0);
text-align: center;
border:none;
box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
inset 0 0 20px rgba(56, 40, 0, 0.4),
inset 0 0 30px rgba(249, 38, 249, 0.8);
cursor: pointer;
font-family: "Heebo";
font-size: 18px;
outline: none;
transition: all ease 1s;
}
.button:hover{
transform: scale(1.1)
}
.right{
    margin-left:auto;
    display: flex;
    text-align: right;
flex:1;

}
.title{
    font-family: "Heebo";
    font-size: 1.5rem;
    text-align: right;
    margin:0 2% auto 0;
}
.description{
    font-family: "HeeboR";
    font-size: 1.1rem;
    text-align: right;
    direction: rtl;
    margin:0 2% auto 0;
}
.image{
    object-fit: cover;
    border-radius: 50%;
          height:150px;
    width:150px;
  
}


@media only screen  and (max-width: 320px)  {

    .what {
        display: flex;
        flex-direction: row;
        width: 95%;
        height:auto;
        border-radius: 100px;
        margin:2% auto;
    }
    .left{
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:auto 0;
    padding:0 20px;
    flex:4;
    }
    .buttonWrapper{
    
    display: flex;
    justify-content: center;
    margin:auto 0;
    
    }
    .button{
    border-radius:20px;
    width:80px;
    height:40px;
    color: rgb(0, 0, 0);
    text-align: center;
    border:none;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(56, 40, 0, 0.4),
    inset 0 0 30px rgba(249, 38, 249, 0.8);
    cursor: pointer;
    font-family: "Heebo";
    font-size: 11px;
    outline: none;
    transition: all ease 1s;
    }
    .button:hover{
    transform: scale(1.1)
    }
    .right{
        margin-left:auto;
        display: flex;
        text-align: right;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .title{
        font-family: "Heebo";
        font-size: 0.8rem;
        text-align: right;
        margin:0 2% auto 0;
    
    }
    .description{
        font-family: "HeeboR";
        font-size: 0.7rem;
        text-align: right;
        direction: rtl;
 
        margin:0 2% auto 0;
    }
    .image{
        object-fit: cover;
        border-radius: 50%;
     
        height: 40px;
        width:40px;
      
    }

}

@media only screen  and (min-width: 320px) and (max-width: 350px) {

    .what {
        display: flex;
        flex-direction: row;
        width: 95%;
        height:auto;
        border-radius: 100px;
        margin:3% auto;
    }
    .left{
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:auto 0;
    padding:0 20px;
    flex:4;
    }
    .buttonWrapper{
    
    display: flex;
    justify-content: center;
    margin:auto 0;
    
    }
    .button{
    border-radius:20px;
    width:80px;
    height:40px;
    color: rgb(0, 0, 0);
    text-align: center;
    border:none;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(56, 40, 0, 0.4),
    inset 0 0 30px rgba(249, 38, 249, 0.8);
    cursor: pointer;
    font-family: "Heebo";

    font-size: 12px;
    outline: none;
    transition: all ease 1s;
    }
    .button:hover{
    transform: scale(1.1)
    }
    .right{
        margin-left:auto;
        display: flex;
        text-align: right;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .title{
        font-family: "Heebo";
        font-size: 0.8rem;
        text-align: right;
        margin:0 2% auto 0;
    
    }
    .description{
        font-family: "HeeboR";
        font-size: 0.75rem;
        text-align: right;
        direction: rtl;
 
        margin:0 2% auto 0;
    }
    .image{
        object-fit: cover;
        border-radius: 50%;
     
        height: 70px;
        width:70px;
      
    }

}

@media only screen  and (min-width: 350px) and (max-width: 400px) {

    .what {
        display: flex;
        flex-direction: row;
        width: 95%;
       height:auto;
        border-radius: 100px;
        margin:3% auto;
    }
    .left{
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:auto 0;
    padding:0 20px;
    flex:4;
    }
    .buttonWrapper{
    
    display: flex;
    justify-content: center;
    margin:auto 0;
    
    }
    .button{
    border-radius:20px;
    width:100px;
    height:40px;
    color: rgb(0, 0, 0);
    text-align: center;
    border:none;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(56, 40, 0, 0.4),
    inset 0 0 30px rgba(249, 38, 249, 0.8);
    cursor: pointer;
    font-family: "Heebo";
    font-size: 12px;
    outline: none;
    transition: all ease 1s;
    }
    .button:hover{
    transform: scale(1.1)
    }
    .right{
        margin-left:auto;
        display: flex;
        text-align: right;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .title{
        font-family: "Heebo";
        font-size: 0.85rem;
        text-align: right;
        margin:0 2% auto 0;
    
    }
    .description{
        font-family: "HeeboR";
        font-size: 0.8rem;
        text-align: right;
        direction: rtl;
 
        margin:0 2% auto 0;
    }
    .image{
        object-fit: cover;
        border-radius: 50%;
     
        height: 70px;
        width:70px;
      
    }

}




@media only screen  and (min-width: 400px) and (max-width: 500px) {

    .what {
        display: flex;
        flex-direction: row;
        width: 95%;
              height:auto;
        border-radius: 100px;
        margin:2% auto;
    }
    .left{
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:auto 0;
    padding:0 20px;
    flex:4;
    }
    .buttonWrapper{
    
    display: flex;
    justify-content: center;
    margin:auto 0;
    
    }
    .button{
    border-radius:20px;
    width:100px;
    height:40px;
    color: rgb(0, 0, 0);
    text-align: center;
    border:none;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(56, 40, 0, 0.4),
    inset 0 0 30px rgba(249, 38, 249, 0.8);
    cursor: pointer;
    font-family: "Heebo";
    font-size: 14px;
    outline: none;
    transition: all ease 1s;
    }
    .button:hover{
    transform: scale(1.1)
    }
    .right{
        margin-left:auto;
        display: flex;
        text-align: right;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .title{
        font-family: "Heebo";
        font-size: 0.9rem;
        text-align: right;
        margin:0 2% auto 0;
    
    }
    .description{
        font-family: "HeeboR";
        font-size: 0.85rem;
        text-align: right;
        direction: rtl;
 
        margin:0 2% auto 0;
    }
    .image{
        object-fit: cover;
        border-radius: 50%;
     
        height: 100px;
        width:100px;
      
    }

}
@media only screen  and (min-width: 500px) and (max-width: 600px) {

    .what {
        display: flex;
        flex-direction: row;
        width: 95%;
              height:auto;
        border-radius: 100px;
        margin:2% auto;
    }
    .left{
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:auto 0;
    padding:0 20px;
    flex:4;
    }
    .buttonWrapper{
    
    display: flex;
    justify-content: center;
    margin:auto 0;
    
    }
    .button{
    border-radius:20px;
    width:110px;
    height:40px;
    color: rgb(0, 0, 0);
    text-align: center;
    border:none;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(56, 40, 0, 0.4),
    inset 0 0 30px rgba(249, 38, 249, 0.8);
    cursor: pointer;
    font-family: "Heebo";
    font-size: 14px;
    outline: none;
    transition: all ease 1s;
    }
    .button:hover{
    transform: scale(1.1)
    }
    .right{
        margin-left:auto;
        display: flex;
        text-align: right;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .title{
        font-family: "Heebo";
        font-size: 1rem;
        text-align: right;
        margin:0 2% auto 0;
    
    }
    .description{
        font-family: "HeeboR";
        font-size: 0.9rem;
        text-align: right;
        direction: rtl;
 
        margin:0 2% auto 0;
    }
    .image{
        object-fit: cover;
        border-radius: 50%;
     
        height: 90px;
        width:90px;
      
    }

}
@media only screen  and (min-width: 600px) and (max-width: 700px) {

    .what {
        display: flex;
        flex-direction: row;
        width: 95%;
              height:auto;
        border-radius: 100px;
        margin:2% auto;
    }
    .left{
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:auto 0;
    padding:0 20px;
    flex:4;
    }
    .buttonWrapper{
    
    display: flex;
    justify-content: center;
    margin:auto 0;
    
    }
    .button{
    border-radius:20px;
    width:110px;
    height:40px;
    color: rgb(0, 0, 0);
    text-align: center;
    border:none;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(56, 40, 0, 0.4),
    inset 0 0 30px rgba(249, 38, 249, 0.8);
    cursor: pointer;
    font-family: "Heebo";
    font-size: 14px;
    outline: none;
    transition: all ease 1s;
    }
    .button:hover{
    transform: scale(1.1)
    }
    .right{
        margin-left:auto;
        display: flex;
        text-align: right;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .title{
        font-family: "Heebo";
        font-size: 1.2rem;
        text-align: right;
        margin:0 2% auto 0;
    
    }
    .description{
        font-family: "HeeboR";
        font-size: 1rem;
        text-align: right;
        direction: rtl;
 
        margin:0 2% auto 0;
    }
    .image{
        object-fit: cover;
        border-radius: 50%;
     
        height: 90px;
        width:90px;
      
    }

}

@media only screen  and (min-width: 700px) and (max-width: 800px) {

    .what {
        display: flex;
        flex-direction: row;
        width: 95%;
              height:auto;
        border-radius: 100px;
        margin:2% auto;
    }
    .left{
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin:auto 0;
    padding:0 20px;
    flex:4;
    }
    .buttonWrapper{
    
    display: flex;
    justify-content: center;
    margin:auto 0;
    
    }
    .button{
    border-radius:20px;
    width:110px;
    height:40px;
    color: rgb(0, 0, 0);
    text-align: center;
    border:none;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    inset 0 0 20px rgba(56, 40, 0, 0.4),
    inset 0 0 30px rgba(249, 38, 249, 0.8);
    cursor: pointer;
    font-family: "Heebo";
    font-size: 14px;
    outline: none;
    transition: all ease 1s;
    }
    .button:hover{
    transform: scale(1.1)
    }
    .right{
        margin-left:auto;
        display: flex;
        text-align: right;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .title{
        font-family: "Heebo";
        font-size: 1.25rem;
        text-align: right;
        margin:0 2% auto 0;
    
    }
    .description{
        font-family: "HeeboR";
        font-size:1rem;
        text-align: right;
        direction: rtl;
 
        margin:0 2% auto 0;
    }
    .image{
        object-fit: cover;
        border-radius: 50%;
     
        height: 100px;
        width:100px;
      
    }

}
.header{
    font-family: "Heebo";
    font-size:4rem;
    text-align: center;
    margin:2% auto;
    direction: rtl;
}

.title{
    font-family: "Heebo";
    font-size: 2.5rem;
    text-align: center;
    margin:2% auto;
    direction: rtl;
}
.description{

    font-family: "HeeboR";
    font-size: 1.2rem;
    text-align: center;
    width:80%;
    margin:2% auto;
    direction: rtl;
}
.icon{
    width:25%;
    margin:0 auto;
}
.wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width:40%;
    padding:20px;
    margin:2% auto;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
    inset 0 0 20px rgba(0, 0, 139, 0.5),
    inset 0 0 30px rgba(128, 0, 128, 0.5);
    border-radius: 30px;
}

.bold{
    display: inline;
    color: rgb(0, 0, 0)
}
.explain{
    font-family: "Heebo";
    font-size: 1.5rem;
    text-align: center;
    width:65%;
direction: rtl;

    margin:3% auto;
}
.wrapper{

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media only screen and (max-width: 350px) {

    .header{
        font-family: "Heebo";
        font-size: 2.2rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    
    .title{
        font-family: "Heebo";
        font-size: 1.4rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
    
        font-family: "HeeboR";
        font-size: 1rem;
        text-align: center;
        width:95%;
        margin:2% auto;
        direction: rtl;
    }
    .icon{
        width:25%;
        margin:0 auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width:80%;
        padding:20px;
        margin:2% auto;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
        inset 0 0 20px rgba(0, 0, 139, 0.5),
        inset 0 0 30px rgba(128, 0, 128, 0.5);
        border-radius: 30px;;
    }
    
    .bold{
        display: inline;
        color: rgb(0, 0, 0)
    }
    .explain{
        font-family: "Heebo";
        font-size:1rem;
        text-align: center;
        width:90%;
    
    
        margin:3% auto;
    }
    .wrapper{
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    
}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .header{
        font-family: "Heebo";
        font-size: 2.3rem;
        text-align: center;
        margin:3% auto;
    
        direction: rtl;
    }
    
    .title{
        font-family: "Heebo";
        font-size: 1.4rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
    
        font-family: "HeeboR";
        font-size: 1rem;
        text-align: center;
        width:95%;
        margin:2% auto;
        direction: rtl;
    }
    .icon{
        width:25%;
        margin:0 auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width:80%;
        padding:20px;
        margin:2% auto;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
        inset 0 0 20px rgba(0, 0, 139, 0.5),
        inset 0 0 30px rgba(128, 0, 128, 0.5);
        border-radius: 30px;;
    }
    
    .bold{
        display: inline;
        color: rgb(0, 0, 0)
    }
    .explain{
        font-family: "Heebo";
        font-size:1rem;
        text-align: center;
        width:90%;
    
    
        margin:3% auto;
    }
    .wrapper{
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .header{
        font-family: "Heebo";
        font-size: 2.5rem;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    
    .title{
        font-family: "Heebo";
        font-size:1.6rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
    
        font-family: "HeeboR";
        font-size: 1rem;
        text-align: center;
        width:95%;
        margin:2% auto;
        direction: rtl;
    }
    .icon{
        width:25%;
        margin:0 auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width:80%;
        padding:20px;
        margin:2% auto;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
        inset 0 0 20px rgba(0, 0, 139, 0.5),
        inset 0 0 30px rgba(128, 0, 128, 0.5);
        border-radius: 30px;;
    }
    
    .bold{
        display: inline;
        color: rgb(0, 0, 0)
    }
    .explain{
        font-family: "Heebo";
        font-size:1.1rem;
        text-align: center;
        width:90%;
    
    
        margin:3% auto;
    }
    .wrapper{
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {

    .header{
        font-family: "Heebo";
        font-size: 2.7rem;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    
    .title{
        font-family: "Heebo";
        font-size:  1.7rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
    
        font-family: "HeeboR";
        font-size: 1rem;
        text-align: center;
        width:95%;
        margin:2% auto;
        direction: rtl;
    }
    .icon{
        width:30%;
        margin:0 auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width:70%;
        padding:20px;
        margin:2% auto;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
        inset 0 0 20px rgba(0, 0, 139, 0.5),
        inset 0 0 30px rgba(128, 0, 128, 0.5);
        border-radius: 30px;;
    }
    
    .bold{
        display: inline;
        color: rgb(0, 0, 0)
    }
    .explain{
        font-family: "Heebo";
        font-size:1.1rem;
        text-align: center;
        width:90%;
    
    
        margin:2% auto;
    }
    .wrapper{
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
@media only screen and (min-width: 650px) and (max-width: 850px) {

    .header{
        font-family: "Heebo";
        font-size: 3rem;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    
    .title{
        font-family: "Heebo";
        font-size: 1.3rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
    
        font-family: "HeeboR";
        font-size: 0.9rem;
        text-align: center;
        width:95%;
        margin:2% auto;
        direction: rtl;
    }
    .icon{
        width:30%;
        margin:0 auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width:40%;
        padding:20px;
        margin:2% auto;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
        inset 0 0 20px rgba(0, 0, 139, 0.5),
        inset 0 0 30px rgba(128, 0, 128, 0.5);
        border-radius: 30px;;
    }
    
    .bold{
        display: inline;
        color: rgb(0, 0, 0)
    }
    .explain{
        font-family: "Heebo";
        font-size:1.1rem;
        text-align: center;
        width:90%;
    
    
        margin:2% auto;
    }
    .wrapper{
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
@media only screen and (min-width: 850px) and (max-width: 1050px) {

    .header{
        font-family: "Heebo";
        font-size: 3.1rem;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    
    .title{
        font-family: "Heebo";
        font-size: 1.4rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
    
        font-family: "HeeboR";
        font-size: 1rem;
        text-align: center;
        width:95%;
        margin:2% auto;
        direction: rtl;
    }
    .icon{
        width:30%;
        margin:0 auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width:40%;
        padding:20px;
        margin:2% auto;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
        inset 0 0 20px rgba(0, 0, 139, 0.5),
        inset 0 0 30px rgba(128, 0, 128, 0.5);
        border-radius: 30px;;
    }
    
    .bold{
        display: inline;
        color: rgb(0, 0, 0)
    }
    .explain{
        font-family: "Heebo";
        font-size:1.2rem;
        text-align: center;
        width:90%;
        margin:2% auto;
    }
    .wrapper{
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {

    .header{
        font-family: "Heebo";
        font-size: 3.5rem;
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    
    .title{
        font-family: "Heebo";
        font-size: 1.8rem;
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
    
        font-family: "HeeboR";
        font-size: 1.1rem;
        text-align: center;
        width:95%;
        margin:2% auto;
        direction: rtl;
    }
    .icon{
        width:30%;
        margin:0 auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width:40%;
        padding:20px;
        margin:2% auto;
        box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
        inset 0 0 20px rgba(0, 0, 139, 0.5),
        inset 0 0 30px rgba(128, 0, 128, 0.5);
        border-radius: 30px;;
    }
    
    .bold{
        display: inline;
        color: rgb(0, 0, 0)
    }
    .explain{
        font-family: "Heebo";
        font-size:1.2rem;
        text-align: center;
        width:90%;
        margin:2% auto;
    }
    .wrapper{
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}
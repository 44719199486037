

.navbar {
 
    display: flex;
    width:100%;

background:none;
 

  height:80px;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
 box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
    box-sizing: border-box;

 
  }
  

.floatingNav {
 
  display: flex;
  width:100%;
  background: none;
position: fixed;
top: -80px;
z-index: 999;
left:0;
transform: translateY(80px);
height:80px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(10px);
  align-items: center;
  justify-content: space-between;
  padding: 20px;
box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
  box-sizing: border-box;
transition: all ease 1.5s;
}

  .logoContainer {
    flex: 1;
    display: flex;

  }

  
  .navLinks {
    position: fixed;
    top: 0;
    right: -100vw;
    width:100vw;
    height: 100vh;
    background-image: linear-gradient(to bottom right, #fc35ff, #c900ff);



    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  
    box-sizing: border-box;
    z-index: 999999;
    transition: right 0.3s ease-in-out;
  }
  
  .openLinks {
    right: 0;
  }
  
  .navLinks ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .navLinks ul li {
    margin: 10px 0;
  }
  


  
  .hamburger {
    cursor: pointer;
  }
  
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #000000;
    transition: all 0.3s ease-in-out;
  }
  .image{
    height:70px;
    object-fit: contain;
    display: flex;
    align-items: center;
  }
  .overlay {
  
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
  z-index: 999;
    opacity: 1;
    z-index: 9999;
    visibility: visible;
  }
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }
  .navLink{

    color:rgb(45, 1, 55);
    font-size: 1.1rem;

font-family: "HeeboR";
cursor: pointer;
transition: all ease 1s;
  }
  .navLink:hover{
  
transform: scale(1.1);

  }

  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 35%;;
  }
.navImage{
  height:100px;
  object-fit: fill;
  margin-top:40px;
}

  @media only screen and (max-width: 350px){



    .navbar {
 
      display: flex;
      width:100%;
  
  background: none;
   
  
    height:80px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      align-items: center;
      justify-content: space-between;
      padding: 20px;
   box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
      box-sizing: border-box;
   transition: all ease-out 1s;
    }
    
  
  .floatingNav {
   
    display: flex;
    width:100%;
    background: none;
  position: fixed;
  top: -80px;
  z-index: 999;
  left:0;
  transform: translateY(80px);
  height:80px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
    box-sizing: border-box;
  transition: all ease-out 1.5s;
  } 
    .image{
      height:50px;
      object-fit: fill;
    }
    .navLinks ul li {
      margin: 20px 0;
    }
    .navImage{
      height:65px;
      object-fit: fill;
    }
  }
  @media only screen  and (min-width: 350px) and (max-width: 450px){


    .navbar {
 
      display: flex;
      width:100%;
  
  background: none;
   
  
    height:80px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      align-items: center;
      justify-content: space-between;
      padding: 20px;
   box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
      box-sizing: border-box;
   transition: all ease-out 1s;
    }
    
  
  .floatingNav {
   
    display: flex;
    width:100%;
    background: none;;
  position: fixed;
  top: -80px;
  z-index: 999;
  left:0;
  transform: translateY(80px);
  height:80px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
    box-sizing: border-box;
  transition: all ease-out 1.5s;
  } 

    .image{
      height:55px;
      object-fit: fill;
    }
    .navImage{
      height:90px;
      object-fit: fill;
    }
    .navLinks ul li {
      margin: 25px 0;
    }

  }
  @media only screen  and (min-width: 450px) and (max-width: 650px){

    .navbar {
 
      display: flex;
      width:100%;
  
  background: none;
   
  
    height:80px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      align-items: center;
      justify-content: space-between;
      padding: 20px;
   box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
      box-sizing: border-box;
   transition: all ease-out 1s;
    }
    
  
  .floatingNav {
   
    display: flex;
    width:100%;
    background: none;
  position: fixed;
  top: -80px;
  z-index: 999;
  left:0;
  transform: translateY(80px);
  height:80px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
    box-sizing: border-box;
  transition: all ease-out 1.5s;
  } 


    .image{
      height:60px;
      object-fit: fill;
    }
    .navImage{
      height:75px;
      object-fit: fill;
    }
    .navLinks ul li {
      margin:30px 0;
    }

  }
  @media only screen  and (min-width: 650px) and (max-width: 850px){

    .navbar {
 
      display: flex;
      width:100%;
  
  background: none;
   
  
    height:80px;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      align-items: center;
      justify-content: space-between;
      padding: 20px;
   box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
      box-sizing: border-box;
   transition: all ease-out 1s;
    }
    
  
  .floatingNav {
    display: flex;
    width:100%;
    background: none;
  position: fixed;
  top: -80px;
  z-index: 999;
  left:0;
  transform: translateY(80px);
  height:80px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
    box-sizing: border-box;
  transition: all ease-out 1.5s;
  } 

    .image{
      height:55px;
      object-fit: fill;
    }
    .navImage{
      height:100px;
    
      object-fit: fill;
    }
    .navLinks ul li {
      margin:35px 0;
    }
  }

  @media only screen  and (min-width: 850px) and (max-width: 1050px){

    .row{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 60%;
    }
  }
  @media only screen  and (min-width: 1050px) and (max-width: 1250px){

    .row{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 45%;
    }
  }